.Board {
  text-align: center;
}

.Footer {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding-top: 1em;
}

.StartScreen {
  text-align: center;
}

.Title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1em;
}
.Row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button {
  background-color: orange;
  min-height: 9em;
  min-width: 9em;
  max-height: 9em;
  max-width: 9em;
}

.StartScreen li {
  text-align: center;
  margin-left: 200px;
  margin-right: 200px;
}
.StartScreen ul {
  background-color: azure;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5em;
  border: 1px solid black;
}

.Cell {
  font-size: 7em; 
  font-weight: bold;
}

.ScoreBoard {
  padding: 1em;
  text-align: center;
}

.GameModeSelector {
  margin: 1em;
  border: 1px solid red;
  background-color: black;
  color: white;
}

.GameModeSelector:hover {
  background-color: lightcoral;
  color: black;
  cursor: pointer;
}

.reset {
  text-align: center;
  margin: 1em;
  border: 1px solid red;
  background-color: black;
  color: white;
  margin-left: 4em;
  margin-right: 4em;
}

.reset:hover {
  background-color: lightcoral;
  color: black;
  cursor: pointer;
}